import { diffDates, startOfDay } from './dateTime';

export const readableDeltaTimestamp = (timestamp: Date | null | undefined) => {
  if (!timestamp) return '';

  const now = startOfDay();
  const then = startOfDay(timestamp);
  const diffDays = diffDates(now, then, 'day');
  const diffWeeks = diffDates(now, then, 'week');
  const diffMonths = diffDates(now, then, 'month');

  if (diffMonths >= 2) return `In ${diffMonths} months`;
  if (diffMonths < 2 && diffWeeks >= 2) return `In ${diffWeeks} weeks`;
  if (diffWeeks < 2 && diffDays > 1) return `In ${diffDays} days`;
  if (diffDays === 0) return 'Today';
  if (diffDays === 1) return 'Tomorrow';
  if (diffDays === -1) return '1 day ago';
  if (diffWeeks > -1 && diffDays < 0) return `${diffDays * -1} days ago`;
  if (diffMonths > -2 && diffWeeks < -1) return `${diffWeeks * -1} weeks ago`;
  if (diffMonths < -2) return `${diffMonths * -1} months ago`;
  return '';
};
